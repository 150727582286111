var showingLeftMenu = false
jQuery(() => {
    jQuery('a#toggle-mobile-menu').on('click', (ev: JQuery.Event) => {
        ev.preventDefault()
        let menu = jQuery('div#mobile-menu-items')
        if (!showingLeftMenu) {
            showingLeftMenu = true
            menu.show()
        } else {
            showingLeftMenu = false
            menu.hide()
        }
    })
})
